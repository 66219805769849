import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Slide from "./Slide";

const deckDims = [1920, 1080];

const deckStyles = {
    width: `${deckDims[0]}px`,
    minWidth: `${deckDims[0]}px`,
    height: `${deckDims[1]}px`,
    minHeight: `${deckDims[1]}px`,
    backgroundImage: "linear-gradient(0deg,rgb(212, 210, 202),rgb(212, 210, 202))",
    margin: "auto",
    position: "relative"
}

const baseArrow = {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
    fontSize: "40px",
    color: "white",
    zIndex: 1,
    cursor: "pointer",
    height: "100px",
    display: "flex",
    alignItems: "center",
    userSelect: "none"
}



const Deck = ({slides}) => {
    const { slideParam = "1" } = useParams();
    let activeIndex = (parseInt(slideParam) || 1) - 1
    if (activeIndex < 0 || activeIndex >= slides.length) activeIndex = 0;
    
    const slide = slides[activeIndex];
    
    const navigate = useNavigate();
    const [scale, setScale] = useState(1);
    const [showNotes, setShowNotes] = useState(false);
    const [transformOrigin, setTransformOrigin] = useState("top center");

    const jumpTo = useCallback((index) => {
        if (index < 0 || index > slides.length - 1)
            index = 0

        navigate(`/deck/${index+1}`);
    }, [navigate, slides]);
    
    const previous = useCallback(() => {
        const index = (parseInt(slideParam) || 1) - 1;
        if(index === 0) return;   
        jumpTo(index-1);
    }, [slideParam, jumpTo]);

    const next = useCallback(() => {
        const index = (parseInt(slideParam) || 1) - 1
        if(index >= slides.length - 1) return;   
        jumpTo(index+1);
    }, [slideParam, jumpTo, slides]);

    
    

    useEffect(() => {
        const handleResize = () => {
            const wRatio = window.innerWidth / deckDims[0];
            const hRatio = window.innerHeight / deckDims[1]; 
            setScale(Math.min(wRatio, hRatio, 1));  
            setTransformOrigin(hRatio < 1? "top center" : "center center");
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleKeyPress = useCallback((event) => {
        switch (event.key) {
            case "ArrowLeft": 
                previous();
                break;
            case "ArrowRight":
                next();
                break;
            case "s":
                setShowNotes(!showNotes);
                break;
            default:
                return;
        }
    }, [previous, next, showNotes, setShowNotes]);
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => document.removeEventListener('keydown', handleKeyPress);
    }, [handleKeyPress]);

    return (
        <div style={{ ...deckStyles, transform: `scale(${scale})`, transformOrigin: transformOrigin}}>
            {activeIndex > 0 &&
                <div onClick={previous} style={{ ...baseArrow, left:"20px"}}>←</div>
            }
            <Slide data={slide} showNotes={showNotes}/>
            {activeIndex < slides.length - 1 &&
                <div onClick={next} style={{ ...baseArrow, right:"20px"}}>→</div>
            }
        </div>
    )
}

export default Deck;