import * as d3 from 'd3';
import chroma from 'chroma-js';
import React, { useRef, useEffect } from 'react';

const AreaChart = ({ set1, set2, palette, width = 600, height = 400 }) => {
  const ref = useRef();

  useEffect(() => {
    console.log('rendered')
    const svg = d3.select(ref.current)
        .attr("viewBox", [0, 0, width, height])
        .style("border-radius", "1rem");

    svg.selectAll('*').remove();

    const x = d3.scaleLinear().domain([0, set1.length - 1]).range([0, width]);
    const y = d3.scaleLinear().domain([0, d3.max([...set1, ...set2])]).range([height, 0]);

    const area = d3.area()
      .x((d, i) => x(i))
      .y0(y(0))
      .y1(d => y(d));

    const line = d3.line()
      .x((d, i) => x(i))
      .y(d => y(d));

    svg.append('path').datum(set1).attr('fill', chroma(palette[0]).brighten(1).hex()).attr('d', area);
    svg.append('path').datum(set2).attr('fill', chroma(palette[1]).brighten(1).hex()).attr('d', area);

    svg.append('path').datum(set1).attr('stroke', palette[0]).attr('fill', 'none').attr('d', line);
    svg.append('path').datum(set2).attr('stroke', palette[1]).attr('fill', 'none').attr('d', line);

  }, [set1, set2, palette, width, height]);

  return  <svg
    ref={ref}
    style={{
        maxHeight: "100%",
        maxWidth: "100%",
        marginRight: "0px",
        marginLeft: "0px",

    }}
    />
};

export default AreaChart;