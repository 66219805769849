import { formatCurrency } from '../utils';

const styles = {
    grid: {
        gridTemplateColumns: "1fr", 
        gridTemplateRows: "var(--callout-height) 1fr"
    }
}

const costs = 70000
const components = [
    {
        label: "Materials",
        value: .4,
        capture: .05,
        captureLabel: "5% of materials cost",
        capturedTotal: costs*.4*.05
    },{
        label: "Equipment",
        value: .07,
        capture: .2,
        captureLabel: "Storage, security, power",
        capturedTotal: 
            200 + //storage
            400 + //security
            1000  //generator - based on united rentals
    },{
        label: "Administrative",
        value: .1,
        capture: .1,
        captureLabel: "Permits, bid prep, invoicing",
        capturedTotal: 600
    },{
        label: "Labor",
        value: .3
    },{
        label: "Permits & Fees",
        value: .03
    },{
        label: "Contractor profit",
        value: .1
    }
]


const UnitEconomics = () => {
    return (
        <div className="slide-content unit-economics" style={styles.grid}>
            <h2 className="callout" style={{gridColumn:"1 / 2"}}>Using a mid-range kitchen remodel in the Bay Area</h2>
            <div style={{margin:"auto", display:"flex"}}>
                <div className="column">
                    <div className="tag">Total costs</div>
                    <div className="bar main">
                        {components.map((c, index) => (
                            <div className="component" key={index} style={{height:`${c.value * 100}%`}}>
                                <div className="label">
                                    <div>{c.label}</div>
                                    <div className="rule"></div>
                                </div>
                                {c.capture &&
                                    <div className="capture" style={{height:`${c.capture * 100}%`}}></div>
                                }
                                <div className="amount">{formatCurrency(costs * c.value)}</div>
                            </div>
                        ))}
                    </div>
                    <h3 className="total">
                        {formatCurrency(costs)}
                    </h3>
                </div>
                <div className="column">
                    <div className="tag">Value captured</div>
                    <div className="bar capture">
                        {components.map((c, index) => (
                            <div className="component" key={index} style={{height:`${c.value * 100}%`, visibility: !!c.capture ? "visible" : "hidden"}}>
                                <div className="capture" style={{height:`${c.capture * 100}%`}}>
                                    <div className="capture-label">
                                        <div className="rule"></div>
                                        <div>{c.captureLabel}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <h3 className="total">
                        {formatCurrency(components.reduce((total, c) => {
                            return total + (c.capturedTotal || 0);
                        }, 0))}
                    </h3>
                </div>
                
            </div>
            
        </div>
    );
}

export default UnitEconomics;
