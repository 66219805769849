export const hexToRGBA = (hex, alpha = 1) => {
    // Remove the '#' character if present
    const cleanedHex = hex.startsWith('#') ? hex.slice(1) : hex;

    // Extract the RGB components from the hex string
    const red = parseInt(cleanedHex.slice(0, 2), 16);
    const green = parseInt(cleanedHex.slice(2, 4), 16);
    const blue = parseInt(cleanedHex.slice(4, 6), 16);

    // Ensure alpha is within the range of 0 to 1
    const clampedAlpha = Math.max(0, Math.min(1, alpha));

    // Return the RGBA color in the 'rgba(r, g, b, a)' format
    return `rgba(${red}, ${green}, ${blue}, ${clampedAlpha})`;
};

export function formatCurrency(value, locale = 'en-US', currency = 'USD') {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    }).format(value);
}