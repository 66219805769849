
const styles = {
    grid: {
        gridTemplateColumns: "1fr", 
        gridTemplateRows: "var(--callout-height) 1fr"
    }
}


const WhyDelivery = () => {
    return (
        <div className="slide-content why-delivery" style={styles.grid}>
            <h2 className="callout" style={{gridColumn:"1 / 2"}}>Force Multiplier: On-demand Delivery <span style={{fontSize:"5rem", padding:"10px", position:"relative", top:"7px"}}>×</span> Portable Storage</h2>
            <div style={{margin:"auto", display:"flex"}}>
                <div className="column">
                    <div className="tag">For our users</div>
                    <ul className="raw-points">
                        <li>
                            <div className="main">Solves multiple pain points</div>
                            <div className="clarification">Effective materials delivery / security / storage</div>
                        </li>
                        <li>
                            <div className="main">Extends the site's operating hours</div>
                            <div className="clarification">Receiving after hours, resources focused on high-value tasks instead of work-blocking store runs</div>
                        </li>
                        <li>
                            <div className="main">Engages a dormant workforce</div>
                            <div className="clarification">A skill-complementing gig platform for 8 million construction professionals</div>
                        </li>
                    </ul>
                </div>
                <div className="column">
                    <div className="tag">For our business</div>
                    <ul className="raw-points">
                        <li>
                            <div className="main">Provides a strong moat</div>
                            <div className="clarification">A physical outpost at every customer site and daily active use</div>
                        </li>
                        <li>
                            <div className="main">"Default alive" floor</div>
                            <div className="clarification">De-risks the technology play with an attractive traditional business</div>
                        </li>
                        <li>
                            <div className="main">Free billboards</div>
                            <div className="clarification">Novel, classy, effective marketing</div>
                        </li>
                    </ul>
                </div>
                
            </div>
            
        </div>
    );
}

export default WhyDelivery;
