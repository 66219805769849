const Cover = () => {
    return (
        <div className="cover" style={{display: "flex", flexDirection: "row", width: "100%"}}>
            <div style={{display: "flex", flex: 1, position: "relative", justifyContent: "center", alignItems: "center"}}>
                <h1>Forebot</h1>
                <div style={{ position: "absolute", padding: "calc(var(--offset)*4)", bottom: 0, left: 0 }}>
                    <h3>Misha Varshavsky</h3>
                    <p>misha@forebot.com</p>
                    <p>(831) 239-8033</p>
                </div>
            </div>
            <div style={{flex: 1, backgroundImage:"url('/img/shipping-container.png')", backgroundSize:"cover", backgroundPosition:"center"}}></div>
        </div>
    );
}

export default Cover;
