const styles = {
    grid: {
        gridTemplateColumns: "5% repeat(3, 1fr)", 
        gridTemplateRows: "var(--callout-height) 1fr"
    },

    column: {
        margin: "8rem 0 20rem",
        paddingLeft: "calc(var(--offset)*2)",
        borderLeft: "1px solid var(--text-lightest)",
        width:"420px"
    },

    title: {
        minHeight: "6rem"
    }
}


const MasterPlan = () => {
    return (
        <div className="slide-content" style={styles.grid}>
            <h2 className="callout" style={{gridColumn:"1 / 5", gridRow: 1}}>Provide value from day one</h2>
            <div></div>
            <div style={{...styles.column, borderLeft:0}}>
                <div className="h0">01</div>
                <h3 style={styles.title}>Activate</h3>
                <h4>Gain adoption through a dead-simple value proposition: on-demand materials delivery in affluent zip codes of CA, TX and FL</h4>
            </div>
            <div style={styles.column}>
                <div className="h0">02</div>
                <h3 style={styles.title}>Expand</h3>
                <h4>Use that adoption to assist in adjacent areas like permit applications, bid creation, financial reporting and expand geographically</h4>
            </div>
            <div style={styles.column}>
                <div className="h0">03</div>
                <h3 style={styles.title}>Spread</h3>
                <h4>Use <em>that</em> adoption to become the tech platform for the densely connected contractor network</h4>
            </div>
        </div>
    );
}

export default MasterPlan;
