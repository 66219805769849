const style = {
    grid: {
        gridTemplateColumns: "repeat(4, 1% 1fr) 1%", 
        gridTemplateRows: "var(--callout-height) 1.6fr 1fr",
        alignItems: "center"
    }
}


const Solution = () => {
    return (
        <div className="slide-content" style={style.grid}>
            <h2 className="callout" style={{gridColumn:"1 / 10"}}>Apply technology to knowledge work in construction</h2>
            
            {/*Columns*/}            
            <div></div>

            <div className="stage-column">
                <div className="stage">Discovery</div>
                <ul>
                    <li>Site Analysis</li>
                    <li>Cost Estimations</li>
                    <li>Feasibility Studies</li>
                </ul>
            </div>
            <div className="stage-divider">⟩</div>

            <div className="stage-column">
                <div className="stage">Design</div>
                <ul>
                    <li>Permit Acquisition</li>
                    <li>Design Review</li>
                    <li>Value Engineering</li>
                </ul>
            </div>
            <div className="stage-divider">⟩</div>

            <div className="stage-column">
                <div className="stage">Planning</div>
                <ul>
                    <li>Budgeting</li>
                    <li>Scheduling</li>
                    <li>Resource Allocation</li>
                    <li>Risk Management</li>
                </ul>
            </div>
            <div className="stage-divider">⟩</div>

            <div className="stage-column">
                <div className="stage">Construction</div>
                <ul>
                    <li style={{marginRight:"-10px"}}>Materials Procurement</li>
                    <li>Sub Coordination</li>
                    <li>Quality Control</li>
                    <li style={{marginRight:"-20px"}}>Safety & Compliance</li>
                </ul>
            </div>
            {/* <div className="stage-divider">⟩</div>

            <div className="stage-column">
                <div className="stage">Inspection</div>
                <ul>
                    <li>Documentation</li>
                    <li>Compliance</li>
                </ul>
            </div> */}

            <div></div>
        </div>
    );
}

export default Solution;