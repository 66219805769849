import React from 'react';
import * as variants from '../slide-variants'
const slideStyles = {
    display: "flex",
    height: "100%"
}


const renderComponent = (componentName, data) => {
    const Component = variants[componentName];
    return <Component data={data} />
}

const Slide = ({data, showNotes}) => {
    return (
        <div className={`slide ${data.className || ''}`} style={slideStyles}>
            <h1>
                {data.titlePrefix && 
                    <span className="prefix">{data.titlePrefix}</span>
                }
                {data.title}
            </h1>
            {data.content?.type ?
                renderComponent(data.content.type, data)
            :
                <div style={{width:"100%", alignSelf: "center", textAlign: "center"}}>no content</div>
            }

            {(showNotes && data.notes?.length > 0) &&
                <ul className="notes">
                    {data.notes?.map((note, i) =>
                        <li key={i}>{note}</li>
                    )}
                </ul>
            }
        </div>
    );
}

export default Slide;
