import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import './App.css';
import Deck from './components/Deck.js';

const slides = [
  {
    title: "",
    content: {
      type: "Cover"
    },
    notes: [
      "tail end of the tech money pipeline - every Mag7 worker just brings their money to folks doing housing",
      "literal physical presence at the customer's site",
      "this is the moat"
    ]
  },{
    title: "The problem",
    content: {
      type: "Problem"
    },
    notes: [
      "digital laggard",
      "leapfrogging"
    ]
  },{
    title: "Market size (US)",
    content: {
      type: "MarketSize"
    },
    notes: []
  },{
    title: "The solution",
    //className: "split",
    content: {
      type: "Solution"
    },
    notes: []
  },{
    title: "Secret master plan",
    content: {
      type: "MasterPlan"
    },
    notes: []
  },{
    title: "Why materials delivery?",
    content: {
      type: "WhyDelivery"
    },
    notes: [
      "Each component amplifies / catalyzes the value of the other. Delivery expands utility of the container from just storage and security, makes it a portal into the store. Having a container allows delivery to happen async and around the clock",
      "impenetrable with industrialized spam",
      "real. painful."
    ]
  },{
    title: "Hardware",
    content: {
      type: "Hardware"
    }
  },{
    title: "Software",
    className: "mellow",
    content: {
      type: "Software"
    }
  },{
    title: "Unit economics (sample)",
    content: {
      type: "UnitEconomics"
    }
  },{
    title: "Team",
    content: {
      type: "Team"
    }
  },{
    title: "Use of funds"
  }
]

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="deck" element={<Deck slides={slides} />}>
        <Route
          path=':slideParam'
          element={<Deck slides={slides} />}
        />
      </Route>
      <Route 
        path='*'
        element={<Navigate to="deck" replace />}
      />
    </Route>
  )
)


function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
