const Software = () => {
    return (
        <div className="slide-content">
            <div style={{display:"flex", height:"100%"}}>
                <div style={{flex:1, display:"flex", flexDirection:"column", height:"100%"}}>
                    <div style={{height:"200px", position:"relative"}}>
                        <div className="version" style={{position:"absolute"}}><div class="tag">V.1</div></div>
                        <div style={{display:"flex"}}>
                            <div style={{flex:1,minWidth:480,textAlign:"center"}}>
                                <h2>Task Interview</h2>
                                <div style={{left:"239px"}} className="callout-line"></div>
                            </div>
                            <div style={{flex:2,minWidth:440,textAlign:"center"}}>
                                <h2 style={{marginLeft:"-55px"}}>Results Preview</h2>
                                <div style={{left:"890px"}} className="callout-line"></div>
                            </div>
                        </div>
                    </div>
                    <div style={{position:"relative", flex:1, display:"flex"}}>
                        <div className="interface-container">
                            <div className="interface">
                                <div className="chat">
                                    <div className="conversation">
                                        <div className="message bot">Solid option for moisture protection; I've added 12 pieces of Gold Bond PURPLE XP 1/2 in. x 4 ft. x 8 ft. to your shopping cart.</div>
                                        <div className="message user">I also need bedroom's worth of that interior paint</div>
                                        <div className="message bot">Sounds good! You will likely need about 4 gallons for 1,500 sq ft of coverage. Last time you got it from Sherwin-Williams - should I add a substitute to your Home Depot order, or start a new cart? </div>
                                        <div className="message user">Has to be SW</div>
                                        <div className="message bot">Absolutely, added 4 gallon of Sherwin-Williams semi-gloss in birch to the list.</div>
                                        <div className="message user">Okay, I need half a dozen rollers too, what do they have at SW?</div>
                                        <div className="message bot">
                                            Here are a few popular options:
                                            <ul class="product-list">
                                                <li>
                                                    <img src="/img/products/rollers-1.jpg" alt="p1"/>
                                                    <div class="name">Purdy Ultra Finish Roller Cover</div>
                                                    <div><b>$3.49</b> · In Store</div>
                                                    {/* / The Purdy® Ultra Finish® paint roller is designed to deliver a super-smooth finish and low splatter. Made with premium microfiber */}
                                                </li>
                                                <li>
                                                    <img src="/img/products/rollers-2.jpg" alt="p2"/>
                                                    <div className="name">Purdy Colossus Professional 3 pack</div>
                                                    <div><b>$7.99</b> · Available</div>
                                                </li>
                                                <li>
                                                    <img src="/img/products/rollers-3.jpg" alt="p3"/>
                                                    <div className="name">Roller Cover Value Pack</div>
                                                    <div><b>$6.59</b> · 2 days</div>
                                                </li>
                                            </ul>
                                        
                                        </div>
                                    </div>
                                    <div className="omnibox">
                                        <div className="reply-input">Your message</div>
                                    </div>

                                </div>
                                <div className="shopping-list">
                                    <table className="cart-table">
                                        <thead className="cart-thead">
                                            <tr>
                                                <th className="cart-th">Product</th>
                                                <th className="cart-th">Rating</th>
                                                <th className="cart-th">Quantity</th>
                                                <th className="cart-th">Amount</th>
                                                <th className="cart-th"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="break">
                                                <td colspan="5" className="store-row">
                                                    <div className="store">
                                                        <h4>Order from</h4>
                                                        <img src="/img/products/sw-logo.png" style={{margin:"-10px 20px", height:"100px"}} alt="Sherman Williams logo"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart-td">
                                                    <div className="product-stack">
                                                        <img src="/img/products/sw-paint.webp" alt="Paint" className="cart-thumbnail"/>
                                                        <div class="double-decker">
                                                            <div className="main">Infinity One-Coat Perfection - Birch</div>
                                                            <div className="clarification">Advanced durability paint and primer, One-Coat&ellip; {/*Perfection, 2hr dry time*/}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="cart-td rating">
                                                    <b>4.8/5</b>
                                                </td>
                                                <td className="cart-td">
                                                    <div className="cart-quantity-control">
                                                        <div className="trigger minus">-</div>
                                                        <input type="text" value="4"/>
                                                        <div className="trigger plus">+</div>
                                                    </div>
                                                </td>
                                                <td className="cart-td">
                                                    <div class="double-decker">
                                                        <div className="main">$119.92</div>
                                                        <div className="clarification">4 × $29.98</div>
                                                    </div>
                                                </td>
                                                <td className="cart-td"> <div className="remove-item">×</div></td>
                                            </tr>
                                            <tr>
                                                <td className="cart-td">
                                                    <div className="product-stack">
                                                        <img src="/img/products/rollers-1.jpg" alt="Rollers" className="cart-thumbnail"/>
                                                        <div class="double-decker">
                                                            <div className="main">Purdy Ultra Finish Roller Cover</div>
                                                            <div className="clarification">The Purdy® Ultra Finish® paint roller is designed&ellip; {/* to deliver a super-smooth finish*/}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="cart-td rating">
                                                    <b>4.3/5</b>
                                                </td>
                                                <td className="cart-td">
                                                    <div className="cart-quantity-control">
                                                        <div className="trigger minus">-</div>
                                                        <input type="text" value="6"/>
                                                        <div className="trigger plus">+</div>
                                                    </div>
                                                </td>
                                                <td className="cart-td">
                                                    <div class="double-decker">
                                                        <div className="main">$20.94</div>
                                                        <div className="clarification">6 × $3.49</div>
                                                    </div>
                                                </td>
                                                <td className="cart-td"> <div className="remove-item">×</div></td>
                                            </tr>
                                            <tr className="break">
                                                <td colspan="5" className="store-row">
                                                    <div className="store">
                                                        <h4>Order from</h4>
                                                        <img src="/img/products/hd-logo.png" style={{margin:"0 20px", height:"55px"}} alt="Home Depot logo"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart-td">
                                                    <div className="product-stack">
                                                        <img src="/img/products/drywall.webp" alt="Drywall" className="cart-thumbnail"/>
                                                        <div class="double-decker">
                                                            <div className="main">Gold Bond PURPLE XP</div>
                                                            <div className="clarification">1/2 in. x 4 ft. x 8 ft. Mold-Resistant Moistur&ellip;</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="cart-td rating">
                                                    <b>4.8/5</b>
                                                </td>
                                                <td className="cart-td">
                                                    <div className="cart-quantity-control">
                                                        <div className="trigger minus">-</div>
                                                        <input type="text" value="4"/>
                                                        <div className="trigger plus">+</div>
                                                    </div>
                                                </td>
                                                <td className="cart-td">
                                                    <div class="double-decker">
                                                        <div className="main">$119.92</div>
                                                        <div className="clarification">4 × $29.98</div>
                                                    </div>
                                                </td>
                                                <td className="cart-td"> <div className="remove-item">×</div></td>
                                            </tr>
                                        </tbody>
                                        <tbody style={{height:"100%", colspan:3}}><tr><td colspan="5"></td></tr></tbody>
                                        <tfoot className="cart-tfoot">
                                            <tr>
                                                <td colspan="5">
                                                    <div className="footer-content">
                                                        <button className="primary">Next</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width:"40px", borderRight:"1px solid var(--text-lightest)"}}></div>
                <div style={{width:"450px", display:"flex", flexDirection:"column", overflow:"hidden"}}>
                    <div style={{flex:1, borderBottom:"1px solid var(--text-lightest)", paddingLeft:"40px"}}>
                        <div class="tag">V.2</div>
                        <ul className="features">
                            <li>Permit Acquisition</li>
                            <li>Cost Estimation</li>
                            <li>Preparing Bids</li>
                        </ul>
                    </div>
                    <div style={{flex:1, padding:"40px"}}>
                        <div class="tag">V.3</div>
                        <ul className="features">
                            <li>Project Tracking</li>
                            <li>Sub Management</li>
                            <li>Invoicing & Financial Reporting</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
            
    );
}

export default Software;
