const styles = {
    grid: {
        gridTemplateColumns: "1fr", 
        gridTemplateRows: "var(--callout-height) 1fr"
    }
}


const MarketSize = () => {
    return (
        <div className="slide-content" style={styles.grid}>
            <h2 className="callout" style={{gridColumn:"1 / 2"}}>~3.5% of the GDP</h2>

            <div className="market" style={{display:"flex"}}>
                <div className="row-titles">
                    <div>Market Size / Year</div>
                    <div>Avg Project Cost</div>
                    <div># Projects / Year</div>
                </div>
                <div className="data-column">
                    <h3>Remodeling</h3>
                    <div className="spotlight-data">$567B</div>
                    <div className="spotlight-data">$18K</div>
                    <div className="spotlight-data">24M</div>
                </div>
                <div className="data-column">
                    <h3>New Construction</h3>
                    <div className="spotlight-data">$430B</div>
                    <div className="spotlight-data">$390K</div>
                    <div className="spotlight-data">1.1M</div>
                </div>
            </div>
        </div>
    );
}

export default MarketSize;
