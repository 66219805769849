import AreaChart from "../components/AreaChart"

const style = {
    grid: {
        gridTemplateColumns: "repeat(3, 3% 1fr) 3%", 
        gridTemplateRows: "var(--callout-height) 1.6fr 1fr",
        alignItems: "center"
    }
}


const Problem = () => {
    return (
        <div className="slide-content" style={style.grid}>
            <h2 className="callout" style={{gridColumn:"1 / 8", gridRow: 1}}>Productivity Growth &mdash; Construction vs Others</h2>
            <div style={{gridColumn:"1 / 8"}} className="graph-container">


                {/* 
                    function generateTweakedSequence(start, end, length) {
                        // Generate linearly spaced sequence
                        const step = (end - start) / (length - 1);
                        const linearSequence = Array.from({ length }, (_, i) => start + i * step);

                        // Randomly tweak each number up or down by up to 5%, except the first and last number
                        const tweakedSequence = linearSequence.map((num, i) => 
                            i !== 0 && i !== length - 1 ? parseFloat((num * (Math.random() * 0.1 + 0.95)).toFixed(5)) : parseFloat(num.toFixed(5))
                        );

                        return tweakedSequence;
                    }
                */}
                <AreaChart
                    set1={[0.4, 0.41616, 0.45259, 0.5274, 0.53439, 0.63953, 0.59778, 0.72793, 0.76849, 0.70221, 0.83023, 0.86431, 0.89522, 0.95013, 0.86446, 0.96178, 0.97745, 1.01496, 1.15101, 1.13]}
                    set2={[0.4, 0.38168, 0.37661, 0.4637, 0.43367, 0.4585, 0.45167, 0.48106, 0.49193, 0.52306, 0.4765, 0.5383, 0.54867, 0.57135, 0.56409, 0.53914, 0.60636, 0.63745, 0.60944, 0.62]}
                    // palette={["#465f3e","#394669"]}
                    palette={["#364859","#182d3a"]}
                    width="900"
                    height="400"
                />
                <div className="legend">
                    <div class="stack">
                        <hr/>
                        <div>
                            <h2>2.8% / year</h2>
                            <div className="description">Economy across industries</div>
                        </div>
                    </div>
                    <div class="stack" style={{position:"relative", top:"14rem"}}>
                        <hr/>
                        <div>
                            <h2>1% / year</h2>
                            <div className="description">Construction</div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Stats */}
            <div></div>
            <div className="stat-column">
                <div className="stat">&gt;20<em>%</em></div>
                <div className="stat-explanation">Average construction <br/> <b>budget overruns</b></div>
            </div>
            <div></div>
            <div className="stat-column">
                <div className="stat">14<em>hr/week</em></div>
                <div className="stat-explanation">Time spent in <b>non-productive</b> <br/> activities by construction professionals</div>
            </div>
            <div></div>
            <div className="stat-column">
                <div className="stat">92<em>%</em></div>
                <div className="stat-explanation">Of all construction projects <br/> <b>miss their deadline</b></div>
            </div>
            <div></div>
        </div>
    );
}

export default Problem;