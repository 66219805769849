const styles = {
    grid: {
        gridTemplateColumns: "repeat(2, 1fr)", 
        gridTemplateRows: "2fr 1fr"
    }
}


const Hardware = () => {
    return (
        <div className="slide-content hardware" style={styles.grid}>
            <div style={{gridColumn:"1 / 3", borderBottom:"1px solid var(--text-lightest)"}}>
                <div className="version"><div class="tag">V.1</div></div>
                <div style={{display:"flex", width:"100%"}}>
                    <div className="features-container">
                        <ul className="features">
                            <li className="title">Exterior</li>
                            <li>
                                Self-reliant Power System
                                <div className="clarification">Solar panels / inverter / batteries</div>
                            </li>
                            <li>
                                360° Security & Lighting
                            </li>
                            <li>
                                Mobile Connectivity
                                <div className="clarification">Cell modem / wifi</div>
                            </li>
                            <li>
                                Electronic Lock
                                <div className="clarification">Cell modem / wifi</div>
                            </li>
                            <li className="title" style={{marginTop:"40px"}}>Interior</li>
                            <li>Durable Shelving</li>
                            <li>
                                360° Security & Lighting
                            </li>
                            <li>
                                Monitoring Sensors
                            </li>
                        </ul>
                    </div>
                    <div className="view-container">
                        <img src="/img/box-outside.avif" alt="Exterior" style={{position:"relative", top:"70px", left:"50px"}}/>
                    </div>
                    <div className="view-container">
                        <img src="/img/box-inside.avif" alt="Interior" style={{position:"relative", top:"35px"}}/>
                    </div>
                </div>
            </div>
            
            <div>
                <div className="version"><div class="tag">V.2</div></div>
                <ul className="features">
                    <li>Core inventory & equipment</li>
                    <li>Energy generation</li>
                </ul>
            </div>
            <div style={{borderLeft:"1px solid var(--text-lightest)", marginTop:"calc(var(--offset)*-2)", paddingTop:"calc(var(--offset)*2)", paddingLeft:"calc(var(--offset)*2)"}}>
                <div className="version"><div class="tag">V.3</div></div>
                <ul className="features">
                    <li>Outdoor LED Display</li>
                    <li>Sanitary Facilities</li>
                </ul>
            </div>
        </div>
    );
}

export default Hardware;
