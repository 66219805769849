const styles = {
    grid: {
        gridTemplateColumns: "5fr 3fr", 
        gridTemplateRows: "4fr 3fr",
        justifyContent: "center"
    }
}

// const colStyle = {
//     display: "grid",
//     gridTemplateColumns: "20rem 1fr", 
//     gridTemplateRows: "auto 1fr", 
//     paddingTop: "calc(var(--callout-height)*2)",
//     gap: "calc(var(--offset)*2)",
// }

// const avatarStyle = {
//     height: "20rem",
//     width: "20rem",
//     borderRadius: "50%", 
//     gridRow: "1 / 3"
// }

const Team = () => {
    return (
        <div className="slide-content team" style={styles.grid}>
            <div style={{gridColumn:"1 / 3", borderBottom:"1px solid var(--text-lightest)"}}>
                <div className="tag">Founders</div>
                <div className="people">
                    <div className="person">
                        <div className="photo"></div>
                        <h3 className="name">Misha Varshavsky</h3>
                        <div className="title">Founder / CEO</div>
                        <div className="bio">
                            <p>Repeat founder with 20+ years building software products, including a tenure as a VPE of a SaaS logistics company with a $250M+ exit.</p>
                            <p>Real estate investor with experience of over a dozen remodeling projects</p>
                        </div>
                        {/* <ul className="logos">
                            <li></li>
                            <li></li>
                        </ul> */}
                    </div>
                    <div className="person"></div>
                    <div className="person"></div>
                </div>
            </div>
            <div>
                <div class="tag">Engineering team</div>
            </div>
            <div style={{borderLeft:"1px solid var(--text-lightest)", marginTop:"calc(var(--offset)*-2)", paddingTop:"calc(var(--offset)*2)", paddingLeft:"calc(var(--offset)*2)"}}>
                <div class="tag">Advisors</div>
            </div>
            {/* <div style={colStyle}>
                <div className="picture-placeholder" style={avatarStyle}></div>
                <h3>Alex Varshavsky</h3>
                <h4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae accusamus tenetur vel quo iusto? Aut incidunt voluptate sint aliquid vero aliquam nostrum, perferendis repellendus, blanditiis impedit consequatur quas architecto omnis debitis eius id temporibus dolore cumque aperiam deleniti ut sed officia sequi qui? Unde mollitia laudantium porro dolor soluta veniam?</h4>
            </div>
            <div style={colStyle}>
                <div className="picture-placeholder" style={avatarStyle}></div>
                <h3>Misha Varshavsky</h3>
                <h4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus natus temporibus itaque nesciunt, necessitatibus dignissimos unde sed nam magnam quis nostrum exercitationem perferendis id tempore dicta! Corporis aspernatur minus, omnis exercitationem, officia deserunt beatae assumenda recusandae ut officiis iure vero ipsam delectus, fugit expedita? At fugit temporibus voluptas modi vitae.</h4>
            </div> */}
        </div>
    );
}

export default Team;
